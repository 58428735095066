
































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {
    @Prop({ default: "" })
    icon?: string;

    @Prop({ default: "Sin título" })
    title?: string;

    @Prop({ default: "" })
    subtitle?: string;
}
