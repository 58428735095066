

















































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {
    // searchText = "";
    searching = false;

    @Prop({ required: false, default: false, type: Boolean })
    hideSearchBar!: boolean;

    @Prop({ default: "" })
    icon?: string;

    @Prop({ default: "Sin título" })
    title?: string;

    @Prop({ default: "Sin título" })
    subtitle?: string;

    @Prop({ default: "Buscar aquí ..." })
    searchLabel?: string;

    @Prop({ default: "", type: String })
    searchText?: string;

    @Prop({ default: false, type: Boolean })
    realtime?: boolean;

    @Prop({ default: false, type: Boolean })
    loading?: boolean;

    lastValue = "";

    created() {
        this.lastValue = this.searchText || "";
    }

    inputSearchText(value: string) {
        if (this.realtime) {
            this.debounce(value);
        } else {
            if (!value) {
                this.updateSearchText(value);
            }
        }
    }

    @Watch("searchText")
    updateSearchText(value: string) {
        const normalizedValue = (value || "").trim();

        if (normalizedValue !== this.lastValue) {
            this.lastValue = normalizedValue;
            this.$emit("searchTextChanged", normalizedValue);
        }
    }

    timeoutID: any = null;

    debounce(value: string) {
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(() => {
            this.updateSearchText(value);
        }, 300);
    }
}
