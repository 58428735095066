










































































































































































































import { mapState } from "pinia";
import { Component, Vue, Watch } from "vue-property-decorator";
import { loginStore } from "./stores/LoginStore";
import { errorStore } from "./stores/ErrorStore";
import { BaseView } from "@/components/BaseView";
// import { MailboxService, MailboxSummary } from "./services/MailboxService";
import { EventBus } from "@/components/EventBus";

import { DataClient, ToastRequest } from "@/services/DataClient";

import LogInView from "./views/Users/LogInView.vue";
import GalleryDialog from "./views/Images/GalleryDialog.vue";
import ContentElementsDialog from "./views/ContentElements/ContentElementsDialog.vue";
import GetMeetingPointInMapDialogVue from "@/views/MeetingPoints/GetMeetingPointInMapDialog.vue";
import GlobalConfirmationDialog from "@/components/Dialogs/GlobalConfirmationDialog.vue";
import GlobalSelectionDialog from "@/components/Dialogs/GlobalSelectionDialog.vue";
import BookingContextMenu from "@/components/Dialogs/BookingContextMenu.vue";
import CommandCenterDialog from "@/views/CommandCenter/CommandCenterDialog.vue";
import ProgressDialog from "@/components/Dialogs/ProgressDialog.vue";
import { GetTimeRecordForCurrentUserResponse, LogUserInResponse } from "@/services/ClientInterfaces";
import CrmDialog from "@/views/Crm/CrmDialog.vue";
import LogViewerDialog from "@/views/Logs/LogViewerDialog.vue";
import { Section, Group, sections } from "@/services/Navigation";
import { Utilities } from "@/services/Utilities";

// interface Section {
//     name: string;
//     groups: Group[];
// }

// interface Group {
//     name: string;
//     icon: string;

//     group?: string | RegExp;

//     items?: Group[];
//     permission?: string;
//     url?: any;
// }

// interface Item {
//     name: string;
//     icon: string;
//     permission: string;
//     url: any;
// }

@Component({
    components: {
        LogInView,
        GalleryDialog,
        ContentElementsDialog,
        GetMeetingPointInMapDialogVue,
        GlobalConfirmationDialog,
        CrmDialog,
        BookingContextMenu,
        CommandCenterDialog,
        ProgressDialog,
        LogViewerDialog,
        GlobalSelectionDialog,
    },
    computed: {
        ...mapState(loginStore, ["authenticated", "details"]),
        ...mapState(errorStore, ["error"]),
    },
})
export default class extends BaseView {
    authenticated!: boolean;
    details!: LogUserInResponse;
    error!: string;

    drawer = true;
    mini = this.isMini;
    collapsed = false;

    sitesLoaded = false;

    mailboxTimestamp = 0;
    bookingsTimestamp = 0;
    lastPhone = "";

    toastVisible = false;
    toast: ToastRequest = {} as ToastRequest;

    userPanelVisible = false;

    timeRecordForCurrentUser: GetTimeRecordForCurrentUserResponse | null = null;
    timeRecordDialog = false;

    checkPermission(requested: string, available: string[]): boolean {
        if (!requested) {
            return true;
        }

        if (!available) {
            return false;
        }

        const isLocalEnvironment = window.location.hostname.includes("localhost");
        const isSuperUser = this.details?.isSuperUser || false;
        const requestedPermissions = requested.split(",");

        for (const p of requestedPermissions) {
            if (p === "local") {
                return isLocalEnvironment;
            }

            if (isSuperUser) {
                return true;
            }

            if (available.includes(p)) {
                return true;
            }
        }

        return false;
    }

    filterGroup(group: Group, permissions: string[]): Group | undefined {
        const result: Group = {
            name: group.name,
            icon: group.icon,
            group: group.group,
            url: group.url,
            permission: group.permission,
            items: [],
        };

        for (const item of group.items || []) {
            if ((item as any).group) {
                const filteredGroup = this.filterGroup(item as Group, permissions);
                if (filteredGroup?.items?.length) {
                    result.items?.push(filteredGroup);
                }
            } else {
                if (this.checkPermission(item.permission || "", permissions)) {
                    result.items?.push(item);
                }
            }
        }

        if (this.checkPermission(group.permission || "", permissions)) {
            return result;
        } else {
            return undefined;
        }
    }

    get isEmployee() {
        return this.details?.employeeId || false;
    }

    get resolvedSections(): Section[] {
        if (!this.sitesLoaded) {
            return [];
        }

        const license = this.details?.license || "1";
        const permissions = this.details?.permissions || [];

        return sections
            .map((section) => {
                const filteredGroups = section.groups.map((group) => this.filterGroup(group, permissions)!).filter((group) => group && (group?.items?.length || group?.url));

                return {
                    name: section.name,
                    showName: section.showName,
                    groups: filteredGroups,
                    licenses: section.licenses,
                };
            })
            .filter((section) => section.groups.length > 0)
            .filter((section) => section.licenses.includes(license));
    }

    get userName() {
        return this.details?.name || "";
    }

    get userPhone() {
        return this.details?.phoneNumber || "";
    }

    get userAvatar() {
        if (!this.details?.imageId) {
            return "";
        }

        return `/uploads/${this.details.imageId}/64`;
    }

    get userInitials() {
        const name = this.userName;
        if (name) {
            const parts = name.split(" ");
            if (parts.length === 1) {
                return parts[0].substring(0, 2);
            } else {
                return parts[0].substring(0, 1) + parts[parts.length - 1].substring(0, 1);
            }
        }
        return "";
    }
    timer: any;

    get snackbar() {
        return this.error !== "";
    }

    set snackbar(value: boolean) {
        this.closeError();
    }

    async mounted() {
        loginStore().initialize();

        EventBus.$on("scrollToTop", () => this.scrollToTop());
        EventBus.$on("updateScroll", () => this.updateScroll());
        EventBus.$on(DataClient.Dialogs.OpenToastEvent, (event: ToastRequest) => this.openToast(event));

        window.addEventListener("keydown", this.keyUp);

        await this.loadWebSites();

        // this.timer = setInterval(() => this.updateSummary(), 1000);
    }

    destroyed() {
        EventBus.$off(DataClient.Dialogs.OpenToastEvent);

        window.removeEventListener("keydown", this.keyUp);

        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    keyUp(event: KeyboardEvent) {
        if (event.ctrlKey && event.key === "7") {
            event.preventDefault();
            // this.scrollToTop();

            const permissions = this.details?.permissions || [];
            if (this.checkPermission("excursions.bookings.booking-center", permissions)) {
                DataClient.CommandCenter.open({});
            }
        }
    }

    openToast(event: ToastRequest) {
        this.toast = event;
        this.toastVisible = true;
    }

    clickToast() {
        if (this.toast && this.toast.buttonRoute) {
            this.$router.push(this.toast.buttonRoute);
        }

        this.toastVisible = false;
    }

    async loadWebSites() {
        const sites = await DataClient.Websites.getAll();
        const baseSection = sections.find((s) => s.name === "SITIOS WEB");

        if (baseSection) {
            baseSection.groups = sites.map((site) => {
                return {
                    name: (site.name || "").toUpperCase(),
                    icon: "mdi-web",
                    group: new RegExp(`^/websites/${site.id}`),
                    items: [
                        {
                            name: "Configuración",
                            icon: "mdi-web",
                            permission: "websites",
                            url: { name: "GetWebsiteConfiguration", params: { id: site.id } },
                        },
                        {
                            name: "Página de inicio",
                            icon: "mdi-home-outline",
                            permission: "websites",
                            url: { name: "GetWebsiteHomeContent", params: { id: site.id } },
                        },
                        {
                            name: "Fichas de actividad",
                            icon: "mdi-ticket",
                            permission: "websites",
                            url: { name: "GetAllActivitySheets", params: { websiteId: site.id } },
                        },
                        {
                            name: "Páginas",
                            icon: "mdi-file-outline",
                            permission: "superuser",
                            url: { name: "GetAllPages", params: { websiteId: site.id } },
                        },
                        {
                            name: "Posts",
                            icon: "mdi-post-outline",
                            permission: "websites",
                            url: { name: "GetAllPosts", params: { websiteId: site.id } },
                        },
                    ],
                };
            });
        }

        this.sitesLoaded = true;
    }

    async logOut() {
        if (this.timeRecordForCurrentUser && this.timeRecordForCurrentUser.canEnd) {
            await this.endTimeRecordFromLogOut();
        }

        await DataClient.Users.logOut({});
        loginStore().logOut();
    }

    closeError() {
        errorStore().hideError();
    }

    searchBookingsByPhone(phone: string) {
        this.$router.push({
            name: "booking-center",
            query: { search: `+${phone}` },
        });
    }

    @Watch("details", { immediate: false })
    async userDetailsChanged() {
        if (this.details) {
            await this.refreshTimeRecords();

            if (this.timeRecordForCurrentUser?.needToStart) {
                setTimeout(() => {
                    this.startTimeRecord();
                }, 1000);
            }
        }
    }

    async refreshTimeRecords() {
        if (this.details?.timeRecordEnabled) {
            this.timeRecordForCurrentUser = await DataClient.TimeRecords.getForCurrentUser();
        } else {
            this.timeRecordForCurrentUser = null;
        }
    }

    async startTimeRecord() {
        await this.refreshTimeRecords();

        if (!this.timeRecordForCurrentUser) {
            return;
        }

        const date = Utilities.formatDateToDDMMYYYY(this.timeRecordForCurrentUser.date);

        const yes = await DataClient.Dialogs.confirmation({
            title: "Control de jornada laboral " + date,
            message: `<strong>¿Comenzar la jornada laboral del ${date} ahora?</strong>`,
            messages: ["El registro de jornada laboral es obligatorio. Puedes posponerlo, pero no olvides iniciar tu jornada laboral más adelante."],
            icon: "mdi-clock-in",
            acceptButtonText: "Sí, iniciar jornada laboral",
            cancelButtonText: "No, lo haré más adelante",
            cancelButtonColor: "red",
            persistent: true,
        });

        if (yes) {
            this.timeRecordForCurrentUser = await DataClient.TimeRecords.startForCurrentUser({});
        }
    }

    async resumeTimeRecord() {
        await this.refreshTimeRecords();

        if (!this.timeRecordForCurrentUser) {
            return;
        }

        const date = Utilities.formatDateToDDMMYYYY(this.timeRecordForCurrentUser.date);

        const yes = await DataClient.Dialogs.confirmation({
            title: "Control de jornada laboral " + date,
            message: `<strong>¿Desea reanudar la jornada laboral del ${date} ahora?</strong>`,
            messages: [
                "A continuación se muestran los periodos contabilizados en su jornada hasta el momento.",
                ...this.timeRecordForCurrentUser.periods.map((period) => `${period.start} - ${period.end} (${period.readableDuration})`),
            ],
            icon: "mdi-clock-in",
            acceptButtonText: "Sí, reanudar jornada laboral",
            cancelButtonText: "No, cancelar",
            cancelButtonColor: "red",
            persistent: true,
        });

        if (yes) {
            this.timeRecordForCurrentUser = await DataClient.TimeRecords.startForCurrentUser({});
        }
    }

    async pauseTimeRecord() {
        await this.refreshTimeRecords();

        if (!this.timeRecordForCurrentUser) {
            return;
        }

        const date = Utilities.formatDateToDDMMYYYY(this.timeRecordForCurrentUser.date);
        const yes = await DataClient.Dialogs.confirmation({
            title: "Control de jornada laboral " + date,
            message: `<strong>¿Desea pausar la jornada laboral del ${date} ahora?</strong>`,
            messages: [
                "A continuación se muestran los periodos contabilizados en su jornada hasta el momento.",
                ...this.timeRecordForCurrentUser.periods.map((period) => `${period.start} - ${period.end || "Hasta ahora"} (${period.readableDuration})`),
            ],
            icon: "mdi-clock-out",
            acceptButtonText: "Sí, pausar jornada laboral",
            cancelButtonText: "No, cancelar",
            cancelButtonColor: "red",
            persistent: true,
        });

        if (yes) {
            this.timeRecordForCurrentUser = await DataClient.TimeRecords.endForCurrentUser({ pause: true });
        }
    }

    async endTimeRecord() {
        await this.refreshTimeRecords();

        if (!this.timeRecordForCurrentUser) {
            return;
        }

        const date = Utilities.formatDateToDDMMYYYY(this.timeRecordForCurrentUser.date);
        const yes = await DataClient.Dialogs.confirmation({
            title: "Control de jornada laboral " + date,
            message: `<strong>¿Desea finalizar la jornada laboral del ${date} ahora?</strong>`,
            messages: [
                "A continuación se muestran los periodos contabilizados en su jornada hasta el momento.",
                ...this.timeRecordForCurrentUser.periods.map((period) => `${period.start} - ${period.end || "Hasta ahora"} (${period.readableDuration})`),
            ],
            icon: "mdi-clock-out",
            acceptButtonText: "Sí, finalizar jornada laboral",
            cancelButtonText: "No, lo haré más adelante",
            cancelButtonColor: "red",
            persistent: true,
        });

        if (yes) {
            this.timeRecordForCurrentUser = await DataClient.TimeRecords.endForCurrentUser({});
        }
    }
    async endTimeRecordFromLogOut() {
        await this.refreshTimeRecords();

        if (!this.timeRecordForCurrentUser) {
            return;
        }

        const date = Utilities.formatDateToDDMMYYYY(this.timeRecordForCurrentUser.date);
        const yes = await DataClient.Dialogs.confirmation({
            title: "Control de jornada laboral " + date,
            message: `<strong>¿Desea finalizar la jornada laboral del ${date} al desconectar su sesión?</strong>`,
            messages: [
                "A continuación se muestran los periodos contabilizados en su jornada hasta el momento.",
                ...this.timeRecordForCurrentUser.periods.map((period) => `${period.start} - ${period.end || "Hasta ahora"} (${period.readableDuration})`),
            ],
            icon: "mdi-clock-out",
            acceptButtonText: "Sí, finalizar jornada y desconectar",
            cancelButtonText: "No, sólo desconectar",
            cancelButtonColor: "red",
            persistent: true,
        });

        if (yes) {
            this.timeRecordForCurrentUser = await DataClient.TimeRecords.endForCurrentUser({});
        }
    }

    showUserPanel() {
        this.refreshTimeRecords();
        this.userPanelVisible = !this.userPanelVisible;
    }

    @Watch("$route")
    scrollToTop() {
        let ref = (this.$refs as any).routerScroll as any;
        if (ref && ref.$el && ref.$el.scrollTop) {
            ref.$el.scrollTop = 0;
            this.updateScroll();
        }

        ref = (this.$refs as any).scroll as any;
        if (ref && ref.$el && ref.$el.scrollTop) {
            ref.$el.scrollTop = 0;
            this.updateScroll2();
        }
    }

    updateScroll() {
        const ref = (this.$refs as any).routerScroll as any;

        setTimeout(() => {
            ref.update();
        }, 100);
    }

    updateScroll2() {
        const ref = (this.$refs as any).scroll as any;

        setTimeout(() => {
            ref.update();
        }, 100);
    }

    get isMini(): boolean {
        return this.$vuetify.breakpoint.width <= 1350;
        // return this.$vuetify.breakpoint.mdAndDown;
    }

    // @Watch("$vuetify.breakpoint.mdAndDown")
    // onBreakpointChange(newValue: boolean) {
    //     if (!newValue) {
    //         this.mini = false;
    //     }
    // }

    @Watch("$vuetify.breakpoint.width")
    onBreakpointChange(newValue: number) {
        if (newValue <= 1350) {
            this.mini = true;
        } else {
            this.mini = false;
        }
    }

    myDocumentation() {
        this.$router.push({
            name: "ViewEmployee",
            params: { id: "me" },
        });
    }
}
